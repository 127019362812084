import Sidebar from '../../components/Common/Sidebar';
import { ContactsListNew } from '../../components/_pages/BookADemo/ContactsList';

const BookADemo = () => {
  return (
    <>
      <Sidebar />
      <ContactsListNew />
    </>
  );
};

export default BookADemo;
