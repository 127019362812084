import Sidebar from '../../components/Common/Sidebar';
import  { PodcastsFramework }  from '../../components/_pages/Podcasts/Podcasts';

const PodcastsPage = () => {
  return (
    <>
      <Sidebar />
      <PodcastsFramework />
    </>
  );
};

export default PodcastsPage;
