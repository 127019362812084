import { useEffect, useState } from "react";
import { PodcastsSubscribe } from './PodcastsSubscribe';
import { PodcastsContact } from "./PodcastsContact";
import { PodcastsEGuide } from "./PodcastsEGuide";
import { PodcastsHighlights } from "./PodcastsHighLights";


const podCastView: Record<number, JSX.Element> = {
  1: <PodcastsSubscribe />,
  2: <PodcastsContact />,
  3: <PodcastsHighlights />,
  4: <PodcastsEGuide />,
}

export const PodcastsFramework = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [podCastTab, setPodCastTab] = useState(1)


  return (
    <>
      <div className="main-container podcasts">

        <div className="podcasts-tab">
          <div className={`item ${podCastTab === 1 ? 'active' : ''}`} onClick={ () => setPodCastTab(1)}>Subscribe</div>
          <div className={`item ${podCastTab === 2 ? 'active' : ''}`} onClick={ () => setPodCastTab(2)}>Contact</div>
          <div className={`item ${podCastTab === 3 ? 'active' : ''}`} onClick={ () => setPodCastTab(3)}>Highlights</div>
          <div className={`item ${podCastTab === 4 ? 'active' : ''}`} onClick={ () => setPodCastTab(4)}>Eguide</div>
        </div>

        {podCastView[podCastTab]}

      </div>
    </>
  );
};
