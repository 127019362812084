import Sidebar from '../../components/Common/Sidebar';
import { ContactsList } from '../../components/_pages/Contacts/ContactsList';

const Contacts = () => {
  return (
    <>
      <Sidebar />
      <ContactsList />
    </>
  );
};

export default Contacts;
