import Sidebar from '../../components/Common/Sidebar';
import  { AIAssessemnt }  from '../../components/_pages/AIassessment/AIassessmentList';

const AIassessmentPage = () => {
  return (
    <>
      <Sidebar />
      <AIAssessemnt />
    </>
  );
};

export default AIassessmentPage;
