import { useAuth0 } from '@auth0/auth0-react';
import { getForms } from '../../../services/forms.services';
import { useEffect } from 'react';
import removeAuthToken from '../../../services/removeAuthHeader';

const Forms = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    getAllForms();
  }, []);
  const handleLogout = () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    sessionStorage.clear();
    localStorage.clear();
    removeAuthToken();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  const getAllForms = async () => {
    const getFormsArgs={
      formTypeId:3,
      limit:15,
    }
    await getForms(getFormsArgs);
  };

  return (
    <>
      <div>forms entered</div>
      <button onClick={handleLogout}>LogOut </button>
    </>
  );
};

export default Forms;
