import Sidebar from '../../components/Common/Sidebar';
import { SubscribeList } from '../../components/_pages/Subscribe/SubscribeList';

const Contacts = () => {
  return (
    <>
      <Sidebar />
      <SubscribeList />
    </>
  );
};

export default Contacts;
