import Sidebar from '../../components/Common/Sidebar';
import  { EBookList }  from '../../components/_pages/EBook/EBookList';

const CaseStudyPage = () => {
  return (
    <>
      <Sidebar />
      <EBookList />
    </>
  );
};

export default CaseStudyPage;
