import { useEffect, useState } from "react";
import { getForms } from "../../../services/forms.services";
import { useNavigate } from "react-router-dom";
import { IForms } from "../../../types/forms";
import ReactPagination from "../../Common/ReactPagination";
import Loading from "../../Common/LoadingScreen";
import Moment from "moment";
import deleteDataByID from "../../../services/delete.services";

import Modal from "react-modal";
import { jwtDecode } from "jwt-decode";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale.css";
import downloadAsFiles from "../../../services/download.services";

export const PodcastsSubscribe = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [aiReadinessData, setAiReadinessData] = useState<any>();
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffSet] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const [selectedData, setSelectedData] = useState<any>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);

  const [deleteDataByIDModal, setDeleteDataByIDModal] = useState(false);
  const [dataID, setDataID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const handleModal = () => setDeleteDataByIDModal(!deleteDataByIDModal);

  const deleteValue = (id: any, firstName: any, lastName: any) => {
    setFirstName(firstName);
    setLastName(lastName);
    setDataID(id);
  };

  useEffect(() => {
    getContacts();
  }, [offset, limit, search]);

  const tokenInfo: any = jwtDecode(
    localStorage.getItem("access_token") as string
  );

  const handleDownload = async () => {
    setButtonDisable(true);
    const fileData = await downloadAsFiles("formTypeId=10");
    // const blob = new Blob([fileData.data], { type: 'text/csv;charset=utf-8;' });

    const selectedFileData = await downloadAsFiles(
      `formTypeId=10${selectedData
        .map((item: any) => `&selectedId=${item.id}`)
        .join("")}`
    );

    let blob: any;
    if (selectedData) {
      blob = new Blob([selectedFileData.data], {
        type: "text/csv;charset=utf-8;",
      });
    } else {
      blob = new Blob([fileData.data], { type: "text/csv;charset=utf-8;" });
    }

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "contacts.csv");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    setButtonDisable(false);

    getContacts();
  };

  const getContacts = async () => {
    const getFormsArgs = {
      formTypeId: 11,
      limit: limit,
      offset: offset,
      name: search,
    };
    try {
      const allcontact = await getForms(getFormsArgs);
      setAiReadinessData(allcontact.data);
      setIsLoading(false);
    } catch (e: any) {
      if (!e.statusCode) {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    // console.log('contacts: ', contacts?.data);

    const includes = aiReadinessData?.data.every((contact: any) => {
      return selectedData?.some((selected: any) => selected.id === contact.id);
    });
    // console.log('includes: ', includes);
    includes ? setIsSelectAllChecked(true) : setIsSelectAllChecked(false);
  }, [aiReadinessData?.data]);

  const handleInput = (event: any) => {
    setSearch(event.target.value);
  };

  const handleDelete = async (id: any) => {
    try {
      deleteDataByID(id);
      handleModal();
      getContacts();
    } catch (e: any) {
      if (!e.statusCode) {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };

  const handleChange = (e: any) => {
    const { id, checked } = e.target;
    // console.log('id: ', id);
    // console.log('checked: ', checked);
    let filteredCheckedData: any = [];
    let allCheckedData: any = [];

    if (id === "selectAll") {
      // console.log('Entered into selectedAll zone..');

      if (
        checked &&
        !aiReadinessData?.data.every((contact: any) => {
          return selectedData?.some(
            (selected: any) => selected.id === contact.id
          );
        })
      ) {
        allCheckedData = [
          ...selectedData,
          ...aiReadinessData?.data.map((contact: any) => ({
            ...contact,
            // checked: true,
          })),
        ];
      } else if (
        !checked &&
        aiReadinessData?.data.every((contact: any) => {
          return selectedData?.some(
            (selected: any) => selected.id === contact.id
          );
        })
      ) {
        allCheckedData = selectedData?.filter(
          (data: any) =>
            !aiReadinessData?.data.some(
              (contact: any) =>
                contact.id === data.id && contact.name === data.name
            )
        );
      }

      if (allCheckedData.length > 0) {
        filteredCheckedData = allCheckedData?.filter(
          (dataItem: any, index: any, self: any) =>
            index ===
            self.findIndex(
              (i: any) => i.id === dataItem.id && i.name === dataItem.name
            )
        );
        setSelectedData(filteredCheckedData);
      } else {
        setSelectedData(allCheckedData);
      }
      setIsSelectAllChecked(checked);
    } else {
      // console.log('Entered into plane zone..');
      // checked ? console.log('yes') : console.log('nope');

      const updatedSelectedData = checked
        ? [
            ...selectedData,
            aiReadinessData?.data.find(
              (contact: any) => contact.id === Number(id)
            ),
          ]
        : selectedData?.filter((contact: any) => contact.id !== Number(id));

      // console.log('Updated Selected Data', updatedSelectedData);

      setSelectedData(updatedSelectedData);

      aiReadinessData?.data.every((contact: any) => {
        return updatedSelectedData?.some(
          (element: any) => element.id === contact.id
        );
      })
        ? setIsSelectAllChecked(true)
        : setIsSelectAllChecked(false);
    }
  };

  return (
    <>
      <section className="">
        <div className="top-section align-items-center row">
          <div className="col-md-7 col-xl-8">
            <div className="xlarge-text-size">Podcasts Subscribe</div>
          </div>
          <div className="col-md-3 col-xl-2 text-right">
            <input
              value={search}
              onChange={handleInput}
              placeholder="Search by name"
            />
          </div>
          <div className="col-md-2 text-right">
            <button
              type="button"
              className="btn"
              onClick={handleDownload}
              disabled={buttonDisable}
            >
              Download{" "}
              {selectedData?.length === 0 ? "all" : selectedData?.length}
            </button>
          </div>
        </div>

        {/* main data */}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
            {isLoading && <Loading />}
              <table className="table">
                <thead>
                  <tr>
                    <th className="xs">
                      <input
                        type="checkbox"
                        id="selectAll"
                        onChange={handleChange}
                        checked={isSelectAllChecked}
                      />
                    </th>
                    <th className="xs">S.No.</th>
                    <th>Email</th>
                    <th>Page Name</th>
                    <th className="sm">Source</th>
                    <th className="sm">Medium</th>
                    <th className="sm">Campaign</th>
                    <th className="sm">Content</th>
                    <th>Submitted on</th>
                    {tokenInfo.permissions[0] === "Delete" ? (
                      <th className="xs">Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {aiReadinessData?.data === ""
                    ? "Empty data"
                    : aiReadinessData?.data.map((contact: IForms, index: any) => {
                        return (
                          <tr key={contact.id}>
                            <td>
                              <input
                                type="checkbox"
                                id={JSON.stringify(contact.id)}
                                checked={selectedData?.some(
                                  (item: any) => item.id === contact.id
                                )}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>{index + offset + 1}</span>
                            </td>
                            <td>
                              <span>{contact.email}</span>
                            </td>
                            <td>
                              <span>{contact.pageName}</span>
                            </td>
                            <td className="sm">
                              <span>{contact.utmSource}</span>
                            </td>
                            <td className="sm">
                              <span>{contact.utmMedium}</span>
                            </td>
                            <td className="sm">
                              <span>{contact.utmCampaign}</span>
                            </td>
                            <td className="sm">
                              <span>{contact.utmContent}</span>
                            </td>
                            <td className="sm">
                              <span className="">
                                {Moment(contact.createdAt).format(
                                  "ddd, MMM Do YYYY, h:mm:ss a"
                                )}
                              </span>
                            </td>
                            {tokenInfo.permissions[0] === "Delete" ? (
                              <>
                                <td className="xs">
                                  <button
                                    className="btn-danger btn"
                                    type="button"
                                    onClick={() => {
                                      handleModal();
                                      deleteValue(
                                        contact.id,
                                        contact.lastName,
                                        contact.firstName
                                      );
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
            <div className="pagination-log">
              <ReactPagination
                totalData={aiReadinessData}
                setLimit={setLimit}
                setOffSet={setOffSet}
                limit={limit}
              />
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={deleteDataByIDModal}
        onRequestClose={handleModal}
        contentLabel="delete-data"
      >
        <div className="head">
          <h4>Remove data</h4>
        </div>
        <div className="m-body">
          <div>
            Are you sure you want to remove this{" "}
            <b>
              {firstName} {lastName}
            </b>{" "}
            data
          </div>
        </div>
        <div className="m-footer">
          <button className="btn btn-link" onClick={handleModal}>
            Close
          </button>{" "}
          {"  "}
          <button
            className="btn btn-danger"
            onClick={() => handleDelete(dataID)}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};
