import ReactPaginate from 'react-paginate';
import { ReactComponent as ArrowRight } from '../../assets/images/chevron-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/images/chevron-left-black.svg';

const limitvalues = [15, 50, 100, 500];

const ReactPagination = (props: any) => {
  const pages = [];
  for (let i = 1; i <= Math.ceil(props.totalData?.total / props.limit); i += 1) {
    pages.push(i);
  }

  const handlePageChange = ({ selected }: { selected: any }) => {
    props.setOffSet(props.limit * selected);
  };

  return (
    <div className="container-pagination">
      <span className="">Show</span>

      <div className="per-page">
        <select
          value={props.limit}
          onChange={({ target: { value } }) => {
            props.setLimit(value);
          }}
        >
          {limitvalues.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowRight />}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(props.totalData?.total / props.limit)}
        previousLabel={<ArrowLeft />}
        renderOnZeroPageCount={null}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
};
export default ReactPagination;
