import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import './assets/sass/style.min.scss'
import axios from 'axios';
import { Router } from './routes/index';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

if (localStorage.getItem('access_token')) {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('access_token')}`;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
        authorizationParams={{
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin + '/callback',
        }}
      >
        <Router />
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
