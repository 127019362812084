import { getForms } from "../../../services/forms.services";
import { useEffect, useState } from "react";
import { IForms } from "../../../types/forms";
import ReactPagination from "../../Common/ReactPagination";
import Loading from "../../Common/LoadingScreen";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import deleteDataByID from "../../../services/delete.services";

import Modal from "react-modal";
import { jwtDecode } from "jwt-decode";
import downloadAsFiles from "../../../services/download.services";

export const WhitepaperList = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [Whitepaper, setWhitepaper] = useState<any>();
  const [limit, setLimit] = useState<number>(15);
  const [offset, setOffSet] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const [selectedData, setSelectedData] = useState<any>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);

  const [deleteDataByIDModal, setDeleteDataByIDModal] = useState(false);
  const [dataID, setDataID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const handleModal = () => setDeleteDataByIDModal(!deleteDataByIDModal);

  const deleteValue = (id: any, firstName: any, lastName: any) => {
    setFirstName(firstName);
    setLastName(lastName);
    setDataID(id);
  };

  const tokenInfo: any = jwtDecode(
    localStorage.getItem("access_token") as string
  );

  const handleDelete = async (id: any) => {
    try {
      deleteDataByID(id);
      handleModal();
      getWhitepaper();
    } catch (e: any) {
      if (!e.statusCode) {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getWhitepaper();
  }, [offset, limit, search]);

  const getWhitepaper = async () => {
    const getFormsArgs = {
      formTypeId: 2,
      limit: limit,
      offset: offset,
      name: search,
    };
    try {
      const allwhitepaper = await getForms(getFormsArgs);
      setWhitepaper(allwhitepaper.data);
      setIsLoading(false);
    } catch (e: any) {
      if (!e.statusCode) {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    const includes = Whitepaper?.data.every((wp: any) => {
      return selectedData?.some((selected: any) => selected.id === wp.id);
    });

    includes ? setIsSelectAllChecked(true) : setIsSelectAllChecked(false);
  }, [Whitepaper?.data]);

  const handleInput = (event: any) => {
    setSearch(event.target.value);
  };

  const handleDownload = async () => {
    setButtonDisable(true);
    const fileData = await downloadAsFiles("formTypeId=2");
    // const blob = new Blob([fileData.data], { type: 'text/csv;charset=utf-8;' });

    const selectedFileData = await downloadAsFiles(
      `formTypeId=2${selectedData
        .map((item: any) => `&selectedId=${item.id}`)
        .join("")}`
    );

    let blob: any;
    if (selectedData) {
      blob = new Blob([selectedFileData.data], {
        type: "text/csv;charset=utf-8;",
      });
    } else {
      blob = new Blob([fileData.data], { type: "text/csv;charset=utf-8;" });
    }

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "WhitepaperList.csv");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    setButtonDisable(false);

    getWhitepaper();
  };

  const handleChange = (e: any) => {
    const { id, checked } = e.target;

    let filteredCheckedData: any = [];
    let allCheckedData: any = [];

    if (id === "selectAll") {
      if (
        checked &&
        !Whitepaper?.data.every((wp: any) => {
          return selectedData?.some((selected: any) => selected.id === wp.id);
        })
      ) {
        allCheckedData = [
          ...selectedData,
          ...Whitepaper?.data.map((wp: any) => ({
            ...wp,
            // checked: true,
          })),
        ];
      } else if (
        !checked &&
        Whitepaper?.data.every((wp: any) => {
          return selectedData?.some((selected: any) => selected.id === wp.id);
        })
      ) {
        allCheckedData = selectedData?.filter(
          (data: any) =>
            !Whitepaper?.data.some(
              (wp: any) => wp.id === data.id && wp.name === data.name
            )
        );
      }

      if (allCheckedData.length > 0) {
        filteredCheckedData = allCheckedData?.filter(
          (dataItem: any, index: any, self: any) =>
            index ===
            self.findIndex(
              (i: any) => i.id === dataItem.id && i.name === dataItem.name
            )
        );
        setSelectedData(filteredCheckedData);
      } else {
        setSelectedData(allCheckedData);
      }
      setIsSelectAllChecked(checked);
    } else {
      const updatedSelectedData = checked
        ? [
            ...selectedData,
            Whitepaper?.data.find((wp: any) => wp.id === Number(id)),
          ]
        : selectedData?.filter((contact: any) => contact.id !== Number(id));

      // console.log('Updated Selected Data', updatedSelectedData);

      setSelectedData(updatedSelectedData);

      Whitepaper?.data.every((wp: any) => {
        return updatedSelectedData?.some(
          (element: any) => element.id === wp.id
        );
      })
        ? setIsSelectAllChecked(true)
        : setIsSelectAllChecked(false);
    }
  };

  return (
    <>
      <section className="main-container">
        <div className="top-section row align-items-center">
          <div className="col-md-7 col-xl-8">
            <div className="xlarge-text-size">Whitepaper</div>
          </div>
          <div className="col-md-3 col-xl-2 text-right">
            <input
              value={search}
              onChange={handleInput}
              placeholder="Search by email"
            />
          </div>
          <div className="col-md-2 text-right">
            <button
              type="button"
              className="btn"
              onClick={handleDownload}
              disabled={buttonDisable}
            >
              Download{" "}
              {selectedData?.length === 0 ? "all" : selectedData?.length}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              {isLoading && <Loading />}
              <table className="table">
                <thead>
                  <tr>
                    <th className="xs">
                      <input
                        type="checkbox"
                        id="selectAll"
                        onChange={handleChange}
                        checked={isSelectAllChecked}
                      />
                    </th>
                    <th className="xs">S.No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Industry</th>
                    <th>Country</th>
                    <th>Pdf Download</th>
                    <th className="lg">Downloaded Form</th>
                    <th className="sm">Source</th>
                    <th className="sm">Medium</th>
                    <th className="sm">Campaign</th>
                    <th className="sm">Content</th>
                    <th>Submitted on</th>
                    {tokenInfo.permissions[0] === "Delete" ? (
                      <th className="xs">Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Whitepaper?.data === ""
                    ? "Empty data"
                    : Whitepaper?.data.map((whitepaper: IForms, index: any) => {
                        return (
                          <tr key={whitepaper.id}>
                            <td>
                              <input
                                type="checkbox"
                                id={JSON.stringify(whitepaper.id)}
                                checked={selectedData?.some(
                                  (item: any) => item.id === whitepaper.id
                                )}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>{index + 1 + offset}</span>
                            </td>
                            <td>
                              <span>
                                {whitepaper.fullName === "null"
                                  ? whitepaper.fullName
                                  : `${whitepaper.firstName} ${whitepaper.lastName}`}
                              </span>
                            </td>
                            <td>
                              <span>{whitepaper.email}</span>
                            </td>
                            <td>
                              <span>{whitepaper.industry}</span>
                            </td>
                            <td>
                              <span>{whitepaper.country}</span>
                            </td>
                            <td>
                              <span>{whitepaper.downloadFileName}</span>
                            </td>
                            <td className="lg">
                              <span>{whitepaper.pageUrl}</span>
                            </td>
                            <td className="sm">
                              <span>{whitepaper.utmSource}</span>
                            </td>
                            <td className="sm">
                              <span>{whitepaper.utmMedium}</span>
                            </td>
                            <td className="sm">
                              <span>{whitepaper.utmCampaign}</span>
                            </td>
                            <td className="sm">
                              <span>{whitepaper.utmContent}</span>
                            </td>
                            <td className="sm">
                              <span className="">
                                {Moment(whitepaper.createdAt).format(
                                  "ddd, MMM Do YYYY, h:mm:ss a"
                                )}
                              </span>
                            </td>
                            {tokenInfo.permissions[0] === "Delete" ? (
                              <>
                                <td className="xs">
                                  <button
                                    className="btn-danger btn"
                                    type="button"
                                    onClick={() => {
                                      handleModal();
                                      deleteValue(
                                        whitepaper.id,
                                        whitepaper.lastName,
                                        whitepaper.firstName
                                      );
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
            <ReactPagination
              totalData={Whitepaper}
              setLimit={setLimit}
              setOffSet={setOffSet}
              limit={limit}
            />
          </div>
        </div>
      </section>

      <Modal
        isOpen={deleteDataByIDModal}
        onRequestClose={handleModal}
        contentLabel="delete-data"
      >
        <div className="head">
          <h4>Remove data</h4>
        </div>
        <div className="m-body">
          <div>
            Are you sure you want to remove this{" "}
            <b>
              {firstName} {lastName}
            </b>{" "}
            data
          </div>
        </div>
        <div className="m-footer">
          <button className="btn btn-link" onClick={handleModal}>
            Close
          </button>{" "}
          {"  "}
          <button
            className="btn btn-danger"
            onClick={() => handleDelete(dataID)}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};
