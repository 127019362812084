import Sidebar from '../../components/Common/Sidebar';
import  { WhitepaperList }  from '../../components/_pages/Whitepaper/WhitepaperList';

const CaseStudyPage = () => {
  return (
    <>
      <Sidebar />
      <WhitepaperList />
    </>
  );
};

export default CaseStudyPage;
