import Sidebar from "../../components/Common/Sidebar";
import { AssetsFramework } from "../../components/_pages/Assets/Assets";

const AssetsPage = () => {
  return (
    <>
      <Sidebar />
      <AssetsFramework />
    </>
  );
};

export default AssetsPage;
