import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import removeAuthToken from '../../../services/removeAuthHeader';


const Logout =()=>{
    const { logout } = useAuth0();
    const handleLogout = () => {
        document.cookie.split(';').forEach((c) => {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
        sessionStorage.clear();
        localStorage.clear();
        removeAuthToken();
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
    };
    
    return (
        <button onClick={handleLogout}>
            <span className="text">Logout</span>
        </button>
    )
}

export default Logout;