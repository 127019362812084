import Sidebar from '../../components/Common/Sidebar';
import  { NRFList }  from '../../components/_pages/NRF-Event/NRFList';

const CaseStudyPage = () => {
  return (
    <>
      <Sidebar />
      <NRFList />
    </>
  );
};

export default CaseStudyPage;
