import { ReactComponent as Loading } from '../../assets/images/Loader.svg';

const LoadingScreen = () => {
  return (
    <div className='loading'>
      <Loading />
    </div>
  );
};

export default LoadingScreen;
