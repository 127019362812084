import Sidebar from '../../components/Common/Sidebar';
import { SyllabusList } from '../../components/_pages/DownloadSyllabus/SyllabusList';

const Syllabus = () => {
  return (
    <>
      <Sidebar />
      <SyllabusList />
    </>
  );
};

export default Syllabus;
