import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../Common/LoadingScreen";

const Callback = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const getAccesstoken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      localStorage.setItem("access_token", accessToken);
      navigate("/contacts");
    } catch (error) {
      console.log("err: ", error);
    }
  };

  useEffect(() => {
    getAccesstoken();
  }, []);
  return <LoadingScreen />;
};

export default Callback;
