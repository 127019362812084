import { getForms } from "../../../services/forms.services";
import { useEffect, useState } from "react";
import { IForms } from "../../../types/forms";
import ReactPagination from "../../Common/ReactPagination";
import Loading from "../../Common/LoadingScreen";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import downloadAsFiles from "../../../services/download.services";

export const AIAssessemnt = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [AIassessment, setAIassessment] = useState<any>();
  const [limit, setLimit] = useState<number>(15);
  const [offset, setOffSet] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const [selectedData, setSelectedData] = useState<any>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);

  useEffect(() => {
    getAIassessment();
  }, [offset, limit, search]);

  const getAIassessment = async () => {
    const getFormsArgs = {
      formTypeId: 7,
      limit: limit,
      offset: offset,
      name: search,
    };
    try {
      const allAIassessment = await getForms(getFormsArgs);
      setAIassessment(allAIassessment.data);
      setIsLoading(false);
    } catch (e: any) {
      if (!e.statusCode) {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    const includes = AIassessment?.data.every((ai: any) => {
      return selectedData?.some((selected: any) => selected.id === ai.id);
    });

    includes ? setIsSelectAllChecked(true) : setIsSelectAllChecked(false);
  }, [AIassessment?.data]);

  const handleInput = (event: any) => {
    setSearch(event.target.value);
  };

  const handleDownload = async () => {
    setButtonDisable(true);
    const fileData = await downloadAsFiles("formTypeId=7");
    // const blob = new Blob([fileData.data], { type: 'text/csv;charset=utf-8;' });

    const selectedFileData = await downloadAsFiles(
      `formTypeId=7${selectedData
        .map((item: any) => `&selectedId=${item.id}`)
        .join("")}`
    );

    let blob: any;
    if (selectedData) {
      blob = new Blob([selectedFileData.data], {
        type: "text/csv;charset=utf-8;",
      });
    } else {
      blob = new Blob([fileData.data], { type: "text/csv;charset=utf-8;" });
    }

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "AIassessmentList.csv");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    setButtonDisable(false);

    getAIassessment();
  };

  const handleChange = (e: any) => {
    const { id, checked } = e.target;

    let filteredCheckedData: any = [];
    let allCheckedData: any = [];

    if (id === "selectAll") {
      if (
        checked &&
        !AIassessment?.data.every((ai: any) => {
          return selectedData?.some((selected: any) => selected.id === ai.id);
        })
      ) {
        allCheckedData = [
          ...selectedData,
          ...AIassessment?.data.map((ai: any) => ({
            ...ai,
            // checked: true,
          })),
        ];
      } else if (
        !checked &&
        AIassessment?.data.every((ai: any) => {
          return selectedData?.some((selected: any) => selected.id === ai.id);
        })
      ) {
        allCheckedData = selectedData?.filter(
          (data: any) =>
            !AIassessment?.data.some(
              (ai: any) => ai.id === data.id && ai.name === data.name
            )
        );
      }

      if (allCheckedData.length > 0) {
        filteredCheckedData = allCheckedData?.filter(
          (dataItem: any, index: any, self: any) =>
            index ===
            self.findIndex(
              (i: any) => i.id === dataItem.id && i.name === dataItem.name
            )
        );
        setSelectedData(filteredCheckedData);
      } else {
        setSelectedData(allCheckedData);
      }
      setIsSelectAllChecked(checked);
    } else {
      const updatedSelectedData = checked
        ? [
            ...selectedData,
            AIassessment?.data.find((ai: any) => ai.id === Number(id)),
          ]
        : selectedData?.filter((contact: any) => contact.id !== Number(id));

      // console.log('Updated Selected Data', updatedSelectedData);

      setSelectedData(updatedSelectedData);

      AIassessment?.data.every((ai: any) => {
        return updatedSelectedData?.some(
          (element: any) => element.id === ai.id
        );
      })
        ? setIsSelectAllChecked(true)
        : setIsSelectAllChecked(false);
    }
  };

  return (
    <>
      <section className="main-container">
        <div className="top-section row align-items-center">
          <div className="col-md-7 col-xl-8">
            <div className="xlarge-text-size">AI Assessment</div>
          </div>
          <div className="col-md-3 col-xl-2 text-right">
            <input
              value={search}
              onChange={handleInput}
              placeholder="Search by email"
            />
          </div>
          <div className="col-md-2 text-right">
            <button
              type="button"
              className="btn"
              onClick={handleDownload}
              disabled={buttonDisable}
            >
              Download{" "}
              {selectedData?.length === 0 ? "all" : selectedData?.length}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              {isLoading && <Loading />}
              <table className="table">
                <thead>
                  <tr>
                    <th className="xs">
                      <input
                        type="checkbox"
                        id="selectAll"
                        onChange={handleChange}
                        checked={isSelectAllChecked}
                      />
                    </th>
                    <th className="xs">S.No.</th>
                    <th>Industry type</th>
                    <th>No. employees</th>
                    <th className="md">level of AI expertise</th>
                    <th className="md">current state Of project</th>
                    <th className="md">Desired achivement</th>
                    <th>Project budget</th>
                    <th>Project objective</th>
                    <th>Email</th>
                    <th>previousPage</th>
                    <th className="sm">Source</th>
                    <th className="sm">Medium</th>
                    <th className="sm">Campaign</th>
                    <th className="sm">Content</th>
                    <th>Submitted on</th>
                  </tr>
                </thead>
                <tbody>
                  {AIassessment?.data === ""
                    ? "Empty data"
                    : AIassessment?.data.map(
                        (AIassessment: IForms, index: any) => {
                          return (
                            <tr key={AIassessment.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  id={JSON.stringify(AIassessment.id)}
                                  checked={selectedData?.some(
                                    (item: any) => item.id === AIassessment.id
                                  )}
                                  onChange={handleChange}
                                />
                              </td>
                              <td>
                                <span>{index + 1 + offset}</span>
                              </td>
                              <td>
                                <span>{AIassessment.industry}</span>
                              </td>
                              <td>
                                <span>{AIassessment.numOfEmployees}</span>
                              </td>
                              <td>
                                <span>{AIassessment.levelOfAiExpertise}</span>
                              </td>
                              <td>
                                <span>
                                  {AIassessment.currentStateOfProject}
                                </span>
                              </td>
                              <td>
                                <span>{AIassessment.desiredAchivement}</span>
                              </td>
                              <td>
                                <span>
                                  ${AIassessment.minProjectBudget} - $
                                  {AIassessment.mixProjectBudget}
                                </span>
                              </td>
                              <td>
                                <span>{AIassessment.projectObjective}</span>
                              </td>
                              <td>
                                <span>{AIassessment.email}</span>
                              </td>
                              <td>
                                <span>{AIassessment.previousPage}</span>
                              </td>
                              <td className="sm">
                                <span>{AIassessment.utmSource}</span>
                              </td>
                              <td className="sm">
                                <span>{AIassessment.utmMedium}</span>
                              </td>
                              <td className="sm">
                                <span>{AIassessment.utmCampaign}</span>
                              </td>
                              <td className="sm">
                                <span>{AIassessment.utmContent}</span>
                              </td>
                              <td className="sm">
                                <span className="">
                                  {Moment(AIassessment.createdAt).format(
                                    "ddd, MMM Do YYYY, h:mm:ss a"
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                </tbody>
              </table>
            </div>
            <ReactPagination
              totalData={AIassessment}
              setLimit={setLimit}
              setOffSet={setOffSet}
              limit={limit}
            />
          </div>
        </div>
      </section>
    </>
  );
};
