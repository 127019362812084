import Sidebar from '../../components/Common/Sidebar';
import  { CaseStudy }  from '../../components/_pages/CaseStudy/CaseStudyList';

const CaseStudyPage = () => {
  return (
    <>
      <Sidebar />
      <CaseStudy />
    </>
  );
};

export default CaseStudyPage;
